<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
        >
          <b-form-group>
            <label
              for="filter_search"
            >
              <strong>
                Search
              </strong>
            </label>
            <b-input
              id="filter_search"
              v-model="tableBrands.filter"
              type="text"
              placeholder="search here"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button
            type="button"
            variant="success"
            @click="onShowAddFormBrand"
          >
            Add Brand
          </b-button>
        </b-col>

      </b-row>

      <b-table
        ref="tableBrands"
        hover
        responsive
        show-empty
        class="mt-2"
        :per-page="tableBrands.perPage"
        :current-page="tableBrands.currentPage"
        :items="myProvider"
        :fields="tableBrands.fields"
        :sort-by.sync="tableBrands.sortBy"
        :sort-desc.sync="tableBrands.sortDesc"
        :sort-direction="tableBrands.sortDirection"
        :filter="tableBrands.filter"
        :filter-included-fields="tableBrands.filterOn"
      >
        <template #cell(index)="row">
          {{ tableBrands.currentPage * tableBrands.perPage - tableBrands.perPage + (row.index + 1) }}
        </template>

        <template #cell(active)="row">
          {{ row.item.active == 1 ? 'Yes' : 'No' }}
        </template>

        <template #cell(brand_name)="row">
          <div class="text-nowrap">
            <strong>{{ row.value }}</strong>
          </div>
        </template>

        <template #cell()="row">
          <div class="text-nowrap">
            {{ row.value }}
          </div>
        </template>

        <template #cell(action)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              @click="onShowEditFormBrand(row.item)"
            >
              Edit
            </b-button>
          </div>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableBrands.perPage"
              :options="tableBrands.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableBrands.currentPage"
            :total-rows="tableBrands.totalRows"
            :per-page="tableBrands.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-brand"
      scrollable
      no-close-on-backdrop
      :title="modalBrandTitle"
      @ok="onValidateFormBrand"
    >
      <ValidationObserver
        ref="formBrand"
      >
        <form
          novalidate
          @submit.prevent
        >

          <ValidationProvider
            #default="{ errors }"
            name="brand name"
            vid="brand_name"
            rules="required|max:100"
          >
            <b-form-group>
              <label for="brand_name">
                <strong>
                  Brand
                </strong>
              </label>
              <b-input
                id="brand_name"
                v-model="brand.brand_name"
                type="text"
                :state="errors.length > 0 ? false : null"
                placeholder="enter brand name"
                autocomplete="off"
                :disabled="state.busy"
                switch
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <div
            v-if="state.editing"
            class="form-group"
          >
            <b-form-checkbox
              id="is_active"
              v-model="brand.active"
              name="is_active"
              value="1"
              unchecked-value="0"
              :disabled="state.busy"
              switch
            >
              Is Active?
            </b-form-checkbox>
          </div>

        </form>
      </ValidationObserver>

      <template #modal-footer="{ok, cancel}">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click="ok()"
        >
          {{ state.editing ? 'Update Record' : 'Save Record' }}
        </b-button>
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>

    </b-modal>
  </b-container>
</template>
<script>
import _ from 'lodash'
import { core } from '@/config/pluginInit'
import { AxiosError, AdminBrandService } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'AdminBrands',

  middleware: ['auth', 'admin'],

  metaInfo () {
    return {
      title: 'Brands'
    }
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        busy: false,
        editing: false
      },
      brand: {
        id: null,
        brand_name: null,
        active: 0
      },
      tableBrands: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        fields: [
          { key: 'index', class: 'text-center' },
          { key: 'action', class: 'text-center' },
          { key: 'brand_name', label: 'brand', sortable: true },
          { key: 'active', class: 'text-center' },
          { key: 'updated_at', formatter: this.dateTimeShortFormatter, sortable: true }
        ]
      }
    }
  },

  computed: {
    modalBrandTitle () {
      return this.state.editing ? 'Edit Brand' : 'Add Brand'
    }
  },

  mounted () {
    core.index()
  },

  methods: {

    async myProvider (ctx) {
      return await AdminBrandService.get(
        this.objectToUrl({
          page: ctx.currentPage,
          per_page: ctx.perPage,
          sort: ctx.sortBy,
          sort_desc: ctx.sortDesc,
          filter_text: ctx.filter
        })
      ).then(({ data }) => {
        this.tableBrands.totalRows = data.total_rows
        return data.items
      }).catch(() => {
        return []
      })
    },

    onShowAddFormBrand () {
      this.state.editing = false
      this.brand.id = null
      this.brand.brand_name = null
      this.brand.active = 1
      this.$bvModal.show('modal-brand')
    },

    onShowEditFormBrand (brand) {
      this.state.editing = true
      this.brand.id = brand.id
      this.brand.brand_name = brand.brand_name
      this.brand.active = brand.active
      this.$bvModal.show('modal-brand')
    },

    async onValidateFormBrand (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.formBrand.validate().then(async success => {
        if (success) {
          let text = 'Do you really want to create brand?'
          if (this.state.editing) {
            text = 'Do you really want to save changes?'
          }
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: text,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              this.state.busy = true
              if (this.state.editing) {
                return this.onPutFormBrand()
              } else {
                return this.onPostFormBrand()
              }
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk(
            'Oops! There were problem with your inputs.', {
              title: 'Validation Error',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              centered: true
            }
          )
        }
      })
    },

    async onPostFormBrand () {
      return new Promise(resolve => {
        AdminBrandService.post(this.brand).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-brand')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.tableBrands.refresh()
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.formBrand.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    async onPutFormBrand () {
      return new Promise(resolve => {
        AdminBrandService.put(this.brand).then(({ data }) => {
          this.$bvModal.hide('modal-brand')
          this.state.busy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            const row = _.find(this.$refs.tableBrands.localItems, { id: data.brand.id })
            row.brand_name = data.brand.brand_name
            row.active = data.brand.active
            row.updated_at = data.brand.updated_at
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.formBrand.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    }
  }
}
</script>
